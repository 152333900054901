.snackbar-icon {
    width: 40px;
}

.snackbar-body {
    width: 100%;
}

.snackbar-action {
    text-align: right;
    width: 220px;
}

.btn {
    border: 1px #FFF solid;
    padding: 4px 15px;
    font-size: 12px;
    min-width: 60px;
    display: inline-block;
    margin-left: 15px;
}

.btn-white {
    background: #fff;
    color: #000;
}

.btn-outline-white {
    background: transparent;
    color: #FFF;
}